import React, { Fragment } from 'react';
import moment from 'moment';

import Text from 'components/UI/Text/Text';
import getServiceColor from 'helpers/getServiceColor';

import {
  StyledRoot,
  StyledTag,
  StyledDate,
  StyledSpace
} from './styled';

const MetaBar = ({ tag, date, additionalContent }) => {
  if (!tag && !date) {
    return null;
  }

  return (
    <StyledRoot>
      {tag && (
        <StyledTag color={getServiceColor(tag)} uppercase bold>
          {tag}
        </StyledTag>
      )}
      {date && (
        <StyledDate>{moment(date).format('DD.MM.YYYY / HH:MM')}</StyledDate>
      )}
      {additionalContent && (
        <Fragment><StyledSpace />{additionalContent}</Fragment>
      )}
    </StyledRoot>
  );
}

export default MetaBar;
