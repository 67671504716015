import styled, { css } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import Text from 'components/UI/Text/Text';

export const StyledRoot = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 22px;

  ${MEDIA.TABLET`
    margin-bottom: 16px;
  `}
`;

export const StyledTag = styled(Text)`
  color: ${({ color }) => color};
`;

export const StyledDate = styled(Text)`
  opacity: .4;
  font-size: 16px;
  margin-left: 44px;
`;

export const StyledSpace = styled.div`
  flex-grow: 1;
  min-width: 10px;
`;

export const StyledShareLinkedin = styled.div`
  color: #007BB6;

  &::before {
    content: '';
    display: inline-flex;
    height: 24px;
    width: 24px;
    background: url(./icons/linkedin.svg) no-repeat center;
  }
`;
